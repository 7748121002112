<template>
  <div class="page-create-app">
    <top-bar back backroute="selectSub" :params="{id: category.parent?.id}" :title="category.parent?.name || ''" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div>
        <n-form>
          <div class="upload-image-place">
            <c-image-upload :file.sync="file" />
            <span v-if="$var('m1')" class="danger">{{ $var('m1') }}</span>
          </div>
          <n-items>
            <div><t name="7.1" />: {{ category.name }}</div>

            <!--            <n-input :placeholder="$app.secure.clean($n.t('7.8'))" v-bind="$form.input('categoryInfo')"></n-input>-->

            <div class="volume-inputs">
              <n-input :placeholder="$app.secure.clean($n.t('7.2'))" v-bind="$form.input('volume')"></n-input>
              <n-select :data="volumeTypes" :placeholder="$app.secure.clean($n.t('7.11'))"
                        v-bind="$form.input('volumeType', 'select')"></n-select>
            </div>

            <n-select class="select-address"
                      :placeholder="$app.secure.clean($n.t('7.3'))"
                      :data="cities" :value.sync="city">
            </n-select>

            <c-address-select :data="addresses" :placeholder="$app.secure.clean($n.t('7.10'))"
                              :value.sync="address" :update:value="selectAddress"
                              @input="changeAddress" />
            <div id="map" />

            <!--            <n-input :placeholder="$app.secure.clean($n.t('7.4'))" v-bind="$form.input('name')"></n-input>-->

            <!--            <n-input :placeholder="$app.secure.clean($n.t('7.9'))" v-bind="$form.input('description')"></n-input>-->

            <div class="to-top">
              <!--              <input :checked="toTop" type="checkbox" @click="toTop = !toTop">-->
              <!--              <div class="title"><t name="7.5" /></div>-->
              <!--              <div class="text"><t name="7.6" /></div>-->
            </div>
          </n-items>
        </n-form>
      </div>

      <n-button wide color="yellow" :disabled="Object.keys(city).length === 0" @click="save"><t name="7.7" /></n-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageCreateApp',
  data() {
    return {
      category: {},
      addresses: [],
      cities: [],
      city: {},
      map: null,
      toTop: false,
      fileId: null,
      file: [],
      url: null,
      address: {},
      volumeTypes: [
        { value: 'kg', title: 'Кг', },
        { value: 'tonn', title: 'Тонн', },
        { value: 'cubes', title: 'Кубов', },
        { value: 'litres', title: 'Литров', },
        { value: 'pieces', title: 'Штук', },
        { value: 'other', title: 'Другое', },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.params.categoryId
    },
  },
  watch: {
    address() {
      console.log(this.address)
    },
    file() {
      this.saveFile()
    },
  },
  mounted() {
    ymaps.ready(this.initMap)
  },
  created() {
    this.$form.init({
      volume: '',
      city: {},
      address: '',
      volumeType: {},
    })
    this.$form.rules({
      volume: [ 'required', ],
      city: [ 'required', ],
      address: [ 'required', ],
      volumeType: [ 'required', ],
    })
    this.load()
    this.loadCities()
  },
  methods: {
    initMap() {
      ymaps.ready().then(() => {
        const coords = [ 43.233975, 76.948567, ]
        this.map = new ymaps.Map('map', {
          center: coords,
          zoom: 10,
        })
        this.map.events.add('click', this.onMapClick)
      })
    },
    loadCities() {
      this.$var('load', true)
      $api.cities.get().with('children').all().then((response) => {
        this.cities = $n.map(response.data.content, (e) => {
          return {
            value: e.id,
            title: e.name,
            children: $n.map(e.children, (c) => {
              return {
                value: c.id,
                title: c.name,
              }
            }),
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    changeAddress(v) {
      return ymaps.geocode(v, {
        boundedBy: this.map.getBounds(),
        results: 10,
      }).then((res) => {
        const addresses = []
        res.geoObjects.each((item) => {
          addresses.push({
            value: item.properties.get('uriMetaData.URI.uri'),
            title: item.properties.get('text'),
            coords: item.geometry.getCoordinates(),
            obj: item,
          })
        })
        this.addresses = addresses
        return res
      })
    },
    selectAddress(v) {
      if (v.obj) {
        this.map.geoObjects.removeAll()
        this.map.geoObjects.add(v.obj)
        this.map.setBounds(v.obj.properties.get('boundedBy'), {
          checkZoomRange: true,
        })
      }
    },

    changeByCoords(coords) {
      ymaps.geocode(coords).then((res) => {
        const firstGeoObject = res.geoObjects.get(0)
        if (firstGeoObject) {
          this.address = firstGeoObject.getAddressLine()
          this.map.geoObjects.removeAll()
          this.myPlacemark = new ymaps.Placemark(coords, {
            balloonContentHeader: firstGeoObject.getPremiseNumber(),
            balloonContentBody: this.address,
          })
          this.map.geoObjects.add(this.myPlacemark)
          const addresses = []
          res.geoObjects.each((item) => {
            addresses.push({
              value: item.properties.get('uriMetaData.URI.uri'),
              title: item.properties.get('text'),
              coords: item.geometry.getCoordinates(),
              obj: item,
            })
          })
          this.selectAddress(addresses[0])
          this[this.selectedVariable] = addresses[0]
          this.addresses = addresses
        }
      })
    },
    onMapClick(event) {
      const coords = event.get('coords')
      this.changeByCoords(coords)
    },
    saveFile() {
      if (this.file.length !== 0) {
        this.$var('load', true)
        $api.files.create({ file: this.file, }).then((response) => {
          this.fileId = response.data.data.id
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    load() {
      this.$var('load', true)
      $api.categories.get(this.id).with('parent').then((response) => {
        this.category = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      // if (this.fileId !== null) {
      this.$var('load', true)
      const data = {
        volume: this.$form.get('volume'),
        volumeType: this.$form.get('volumeType.value'),
        address: this.address.title,
        latitude: this.address.coords[0]+'',
        longitude: this.address.coords[1]+'',
        cityId: this.city.value,
        checkbox: this.toTop,
        categoryId: this.category.id,
        // image: this.fileId,
      }
      $api.application.create(data).then(() => {
        this.$router.push({ name: 'index', })
      }).catch((err) => {
      }).finally(() => {
        this.$var('load', false)
      })
      // } else {
      //   this.$var('m1', 'Фото обязательно')
      // }
    },
  },
}
</script>

<style scoped lang="scss">
.page-create-app {

  .volume-inputs {
    display: flex;
    align-items: center;
    :deep() {
      .n-input {
        margin-right: 15px;
      }
      .n-content {
        min-height: 48px;
      }
      .n-wrapper {
        border: none;
      }
    }
  }

  #map {
    width: 100%;
    height: 300px;
  }

  .danger {
    margin-top: 10px;
    color: red;
  }
  .to-top{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
  }
  .map-place {
    display: none;
  }
  .select-address {
    ::v-deep .n-wrapper {
      border: none;
    }
  }
}
</style>
